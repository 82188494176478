<template>
  <div>
    <v-list v-if="elevator.extraDocuments.length > 0">
      <v-subheader>Documents supplémentaires</v-subheader>
      <v-divider/>
      <template v-for="extra in elevator.extraDocuments">
        <v-list-item
          :key="extra.id"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ extra.document.originalName }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <div class="d-flex">
              <v-btn icon @click="open(extra)">
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn
                icon color="error"
                @click="$refs.removeForm.open(extra)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="'divider_' + extra.id"/>
      </template>
    </v-list>
    <v-list v-else>
      <v-subheader class="d-flex justify-center">Aucun document supplémentaires</v-subheader>
    </v-list>
    <div
      v-if="$store.state.auth.user.isAdmin"
      class="d-flex justify-center"
    >
      <v-btn @click="$refs.uploadForm.open(elevator)" small>
        Ajouter un document
      </v-btn>
    </div>
    <RemoveItem
      ref="removeForm"
      prevent-remove
      @remove="remove"
      />
    <UploadForm
      ref="uploadForm"
      mode="elevator"
      @finished="$emit('reload')"
    />
  </div>
</template>

<script>
import UploadForm from "@/components/UploadForm"
import RemoveItem from "@/components/RemoveItem"

export default {
  components: { RemoveItem, UploadForm },
  props: {
      elevator: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        //
      }
    },
    computed: {
      //
    },
    methods: {
      open(extra) {
        window.open(extra.document.url, '_blank')
      },
      remove(extra) {
        this.$store.dispatch('elevators/removeExtraDocument', {
          elevator: this.elevator,
          extraDocument: extra
        }).then(() => {
          this.$refs.removeForm.close()
          this.$emit('reload')
          this.$store.commit('alert/add', {
            type: 'success',
            message: 'Le document a été supprimé'
          })
        }).catch(err => {
          this.$refs.removeForm.close()
          console.error(err)
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Une erreur est survenue lors de la suppression du document"
          })
        })
      }
    }
  }
</script>
