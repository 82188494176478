<template>
  <div>
    <!--    <v-breadcrumbs-->
    <!--      v-if="elevator"-->
    <!--      class="py-2"-->
    <!--      :items="[-->
    <!--        {-->
    <!--          text: 'Dashboard',-->
    <!--          disabled: false,-->
    <!--          to: { name: 'Dashboard' },-->
    <!--        },-->
    <!--        {-->
    <!--          text: `Chantier`,-->
    <!--          disabled: false,-->
    <!--          to: { name: 'SiteElevators', params: { id: elevator.site.id }},-->
    <!--        },-->
    <!--        {-->
    <!--          text: `Documents de l'ascenseur ${elevator.code}`,-->
    <!--          disabled: true-->
    <!--        },-->
    <!--      ]"-->
    <!--    />-->
    <v-progress-linear
      v-show="loading"
      indeterminate
    />
    <div v-if="elevator">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col :cols="12" :md="6">
              <v-list v-if="documents.length > 0">
                <v-subheader>Documents des cycles de bennes</v-subheader>
                <v-divider/>
                <template v-for="(document, i) in documents">
                  <v-list-item
                    :key="document.id"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ document.originalName }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="$store.state.debugMode">
                        Cycle : {{ document.cycle.id }}
                        |
                        Container :
                        <router-link
                          :to="{ name: 'ContainerCycles', params: { id: document.cycle.container.id} }">
                          {{ document.cycle.container.id }}
                        </router-link>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="openDocument(document)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="'dividier_' + i"/>
                </template>
              </v-list>
              <v-list v-else>
                <v-subheader class="d-flex justify-center">Aucun document supplémentaires</v-subheader>
              </v-list>
            </v-col>
            <v-col :cols="12" :md="6">
              <ElevatorExtraDocument
                :elevator="elevator"
                @reload="$emit('reload')"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import ElevatorExtraDocument from "@/components/Elevators/ElevatorExtraDocument"

export default {
  components: {
    ElevatorExtraDocument
  },
  props: {
    elevator: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    documents: [],
    loading: false,
  }),
  mounted() {
    this.fetchData()
  },
  watch: {
    elevator() {
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      if (!this.elevator) {
        return
      }

      let documents = []
      this.elevator.fillingParts.forEach(part => {
        if (part.containerCycle.document.key) {
          documents.push({ ...part.containerCycle.document, cycle: part.containerCycle })
        }
      })

      this.documents = documents

      // this.loading = true
      // this.$store.dispatch(
      //   'elevators/fetchOne',
      //   this.elevator.id
      // ).then(res => {
      //   this.loading = false
      //   this.elevator = res
      //
      // }).catch(() => {
      //   this.$store.commit('alert/add', {
      //     type: 'error',
      //     text: "Impossible de récupérer l'ascenseur"
      //   })
      //   this.loading = false
      // })
    },
    openDocument(document) {
      window.open(document.url, '_blank')
    }
  }
}
</script>
